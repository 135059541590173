import React from 'react';
import { SecondaryTypography } from '../../../../ui/typography/typography';

const JobPostDetails = () => {

    const handleButtonClick = () => {
      console.log();
    };

    return (
        <div className='w-full bg-gray-100 my-2 p-2'>
            <div className='flex'>
                <div className='py-2 px-4 w-7/12 h-full shadow-md rounded-lg bg-white'>
                    <div className='mb-3.5'>
                        <SecondaryTypography.Large
                            fontWeight='bold'
                            className='mt-96'
                        >
                            Full Stack Developer
                        </SecondaryTypography.Large>

                    </div>
                    <div className='w-2/5 mb-3.5'>
                        <div className='flex items-center justify-between mb-3.5'>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                Location:
                            </SecondaryTypography.XSmall>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                Melbourne, Australia
                            </SecondaryTypography.XSmall>
                        </div>
                        <div className='flex items-center justify-between mb-3.5'>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                Department:
                            </SecondaryTypography.XSmall>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                Engineering
                            </SecondaryTypography.XSmall>
                        </div>
                        <div className='flex items-center justify-between mb-3.5'>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                Working method:
                            </SecondaryTypography.XSmall>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                Hybrid(Remote/Office)
                            </SecondaryTypography.XSmall>
                        </div>
                    </div>
                    <div className='mb-3.5'>
                        <SecondaryTypography.XSmall
                            fontWeight='bold'
                        >
                            About HiAcuity
                        </SecondaryTypography.XSmall>
                        <div className='mt-1'>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                HiAcuity is a AI-Tech startup organisation aiming to transform the recruitment industry with a
                                cutting edge platform. We are committed to being on the edge of the latest industry innovations.

                                We are looking for people who are curious, honest and hard-working. People who would thrive
                                being
                                part of a fast-moving, fast-thinking growth company.
                            </SecondaryTypography.XSmall>
                        </div>
                    </div>
                    <div className='mb-3.5'>
                        <SecondaryTypography.XSmall
                            fontWeight='bold'
                        >
                            The Role
                        </SecondaryTypography.XSmall>
                        <div className='mt-1'>
                            <SecondaryTypography.XSmall
                                fontWeight='semi-bold'
                            >
                                We are seeking a Full-stack Developer to lead the design and implementation of innovative IT
                                solutions. This role offers the opportunity to apply technical expertise while taking on
                                leadership
                                responsibilities to guide project successes and team growth. Collaboration with cross-functional
                                teams will be pivotal in developing and implementing a wide range of IT solutions.
                            </SecondaryTypography.XSmall>
                        </div>

                    </div>
                    <SecondaryTypography.XSmall
                        fontWeight='bold'
                        className='mb-2' // Add margin-bottom for spacing
                    >
                        Responsibilities
                    </SecondaryTypography.XSmall>
                    <ul className='list-disc list-inside mb-4'>
                        <li className='text-xs mb-2 mt-1 pl-4 font-medium'>
                            Design and develop new cloud solutions, web applications, web services, APIs, and databases,
                            emphasize clear and comprehensive documentation.
                        </li>
                        <li className='text-xs mb-2 pl-4 font-medium'>
                            Implement robust full-stack solutions using TypeScript, ReactJS, Node.JS, and
                            ProstgreSQL/MySQL,
                            Python; employ unit testing with jest.
                        </li>
                        <li className='text-xs mb-2 pl-4 font-medium'>
                            Engage in database design, implementation and administration.
                        </li>
                        <li className='text-xs mb-2 pl-4 font-medium'>
                            Manage application versions and collaborations using git.
                        </li>
                    </ul>
                    <SecondaryTypography.XSmall
                        fontWeight='bold'
                        className='mb-2' // Add margin-bottom for spacing
                    >
                        Required Technical Skills and Experience
                    </SecondaryTypography.XSmall>
                    <ul className='list-disc list-inside mb-4'>
                        <li className='text-xs mb-2 mt-1 pl-4 font-medium'>
                            Design and develop new cloud solutions, web applications, web services, APIs, and databases,
                            emphasize clear and comprehensive documentation.
                        </li>
                        <li className='text-xs mb-2 pl-4 font-medium'>
                            Implement robust full-stack solutions using TypeScript, ReactJS, Node.JS, and
                            ProstgreSQL/MySQL,
                            Python; employ unit testing with jest.
                        </li>
                        <li className='text-xs mb-2 pl-4 font-medium'>
                            Engage in database design, implementation and administration.
                        </li>
                        <li className='text-xs mb-2 pl-4 font-medium'>
                            Manage application versions and collaborations using git.
                        </li>
                    </ul>
                    <SecondaryTypography.XSmall
                        fontWeight='bold'
                        className='mb-2' // Add margin-bottom for spacing
                    >
                        Desired Skills and Experience
                    </SecondaryTypography.XSmall>
                    <ul className='list-disc list-inside mb-4'>
                        <li className='text-xs mb-2 mt-1 pl-4 font-medium'>
                            Design and develop new cloud solutions, web applications, web services, APIs, and databases,
                            emphasize clear and comprehensive documentation.
                        </li>
                    </ul>
                    <SecondaryTypography.XSmall
                        fontWeight='bold'
                    >
                        Additional Requirements
                    </SecondaryTypography.XSmall>
                    <ul className='list-disc list-inside mb-4'>
                        <li className='text-xs mb-2 mt-1 pl-4 font-medium'>
                            Design and develop new cloud solutions, web applications, web services, APIs, and databases,
                            emphasize clear and comprehensive documentation.
                        </li>
                    </ul>
                    <SecondaryTypography.XSmall
                        fontWeight='bold'
                    >
                        Perks and Benefits
                    </SecondaryTypography.XSmall>
                </div>
                <div
                    className='p-2 w-4/12 shadow-md rounded-lg bg-white'
                >
                    <div className='flex justify-center'>
                        <button
                            onClick={handleButtonClick}
                            className="w-4/5 bg-blue-500 font-semibold text-white py-2 px-4 rounded hover:bg-blue-600 transition-all"
                        >
                            Apply
                        </button>
                    </div>
                    <div className='flex justify-center mt-3.5'>
                        <button
                            onClick={handleButtonClick}
                            className="w-4/5 bg-blue-500 font-semibold text-white py-2 px-4 rounded hover:bg-blue-600 transition-all"
                        >
                            Email a friend
                        </button>
                    </div>
                    <div
                        className='flex items-center justify-center mt-3.5 bg-gray-400'>
                        <video
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default JobPostDetails;