import React from 'react';
import { useParams } from 'react-router-dom';
import { getApplicantCsv } from '../../../../../utils/api_service/JobOpenings';
import { JobApplicantCSV } from '../../../../../utils/types/JobOpenings';

type ExportCsvProps = {
    searchCSVObject: JobApplicantCSV,
    loading: boolean,
}

export const ExportCsv = ({
  searchCSVObject,
  loading,
}:ExportCsvProps) => {

  const { id } = useParams<{ id: string }>();

  const handleExportClick = async () => {
    const response = await getApplicantCsv(id, searchCSVObject);
    const responseData = await response.text();
    const blob = new Blob([responseData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'applicants.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
      <div className={`${loading ? 'animate-pulse' : ''}`}>
        { loading ? (
            <div className='rounded-md w-[125px] mt-5 h-10 bg-gray-300'>
            </div>
        ) : (
            <div className='flex justify-center items-center mt-5'>
              <button
                  className='px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300'
                  onClick={handleExportClick}
              >
                Export as CSV
              </button>
            </div>
        ) }
      </div>
  );
};

export default ExportCsv;
