import { Editor } from '@tiptap/react';
import React from 'react';
import {
  BlockQouteIcon,
  BoldIcon,
  BulletListIcon,
  H1Icon,
  H2Icon,
  H3Icon,
  HorizontalRuleIcon,
  ItalicIcon,
  OrderedListIcon,
  RedoIcon,
  UndoIcon,
} from '../../icons/icons';
import { IconButton } from './IconButton';

interface EditorToolbarProps {
  editor: Editor | null;
}

const EditorToolbar: React.FC<EditorToolbarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const BUTTON_DATA = [
    {
      onClick: () => editor.chain().focus().toggleBold().run(),
      icon: <BoldIcon/>,
    },
    {
      onClick: () => editor.chain().focus().toggleItalic().run(),
      icon: <ItalicIcon/>,
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      icon: <H1Icon/>,
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      icon: <H2Icon/>,
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      icon: <H3Icon/>,
    },
    {
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      icon: <BulletListIcon/>,
    },
    {
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      icon: <OrderedListIcon/>,
    },
    {
      onClick: () => editor.chain().focus().toggleBlockquote().run(),
      icon: <BlockQouteIcon/>,
    },
    {
      onClick: () => editor.chain().focus().setHorizontalRule().run(),
      icon: <HorizontalRuleIcon/>,
    },
    {
      onClick: () => editor.chain().focus().undo().run(),
      icon: <UndoIcon/>,
    },
    {
      onClick: () => editor.chain().focus().redo().run(),
      icon: <RedoIcon/>,
    },
  ];

  return (
    <div className='flex flex-row rounded bg-gray-200 mb-3'>
      { BUTTON_DATA.map((button) => (
        <IconButton
            onClick={button.onClick}
            icon={button.icon}
        />
      )) }
    </div>
  );
};

export default EditorToolbar;
