import React from 'react';

type NumberInfoProps = {
    value: number,
    name: string,
    loading: boolean,
}

export const NumberInfo = ({
  value,
  name,
  loading
}:NumberInfoProps) => {

  return (
      <div className={`${loading ? 'animate-pulse' : ''}`}>
          { loading ? (
              <div className='rounded-md w-[105px] h-20 bg-gray-300'>
              </div>
          ) : (
              <div className='flex flex-col items-center justify-center'>
                  <p className='text-7xl p-1 text-center font-bold text-green-500'>{value}</p>
                  <p className='text-center font-bold text-green-500 text-xs'>{name}</p>
              </div>
          ) }
      </div>
  );
};
