import React, { useState } from 'react';
import { JobPostingData, FilterState } from '../../../../utils/types/JobOpenings';
import { ChevronDown } from '../../../../ui/icons/icons';

export type JobPostingFilterProps = {
  jobData: JobPostingData[];
  onFilterChange: (filters: FilterState) => void;
}

const JobPostingFilter = ({
  jobData,
  onFilterChange,
}: JobPostingFilterProps) => {

  const [selectedFilters, setSelectedFilters] = useState<FilterState>({
        department: [],
        country: [],
        state: [],
        city: [],
        remote: [],
    });

  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({
        department: false,
        country: false,
        state: false,
        city: false,
        remote: false,
    });

    // Extract unique filter options
    const departments = Array.from(new Set(jobData.map(job => job.department).filter(Boolean)));
    const states = Array.from(new Set(jobData.map(job => job.state).filter(Boolean)));
    const countries = Array.from(new Set(states.map(state => state.split(', ')[1])));
    const cities = Array.from(new Set(states.map(state => state.split(', ')[0])));
    const remoteOptions = ["Remote", "Semi-remote", "Onsite"];

    const countOccurrences = (array: string[], getKey: (item: string) => string) =>
        array.reduce((acc, item) => {
            const key = getKey(item);
            acc[key] = (acc[key] || 0) + 1;
            return acc;
        }, {} as { [key: string]: number });

    // Counts for departments, states, countries, and cities
    const departmentCounts = countOccurrences(
        jobData.map(job => job.department).filter(Boolean),
        item => item
    );

    const stateCounts = countOccurrences(
        jobData.map(job => job.state).filter(Boolean),
        item => item
    );

    const countryCounts = countOccurrences(
        jobData.map(job => job.state.split(', ')[1]).filter(Boolean),
        item => item
    );

    const cityCounts = countOccurrences(
        jobData.map(job => job.state.split(', ')[0]).filter(Boolean),
        item => item
    );

    // Handle filter selection
    const handleFilterChange = (filterType: keyof FilterState, value: string) => {
        setSelectedFilters(prevState => {
            const isSelected = prevState[filterType].includes(value);
            const updatedFilters = isSelected
                ? prevState[filterType].filter(item => item !== value)
                : [...prevState[filterType], value];

            const newFilters = { ...prevState, [filterType]: updatedFilters };
            onFilterChange(newFilters);
            return newFilters;
        });
    };

    const toggleDropdown = (filterType: keyof FilterState) => {
        setIsOpen(prevState => ({
            ...prevState,
            [filterType]: !prevState[filterType],
        }));
    };

    const renderFilterOptions = (options: string[], filterType: keyof FilterState, counts: { [key: string]: number }) => (
        <div
            className={`absolute mt-1 bg-white border rounded shadow-lg max-h-48 max-w-md overflow-y-scroll overflow-x-hidden ${
                isOpen[filterType] ? 'block' : 'hidden'
            }`}
        >
            {options.map(option => (
                <div key={option} className="flex px-4 py-2 hover:bg-gray-100">
                    <input
                        type="checkbox"
                        checked={selectedFilters[filterType].includes(option)}
                        onChange={() => handleFilterChange(filterType, option)}
                        className="mr-2"
                    />
                    <label>{option}</label>
                    {counts[option] !== undefined && (
                        <span className="text-gray-500">({counts[option]})</span>
                    )}
                </div>
            ))}
        </div>
    );

    return (
        <div className="flex justify-evenly mb-4">
            <div className="relative">
                <div
                    onClick={() => toggleDropdown('department')}
                    className="flex text-sm items-center cursor-pointer hover:font-semibold"
                >
                    Department <ChevronDown/>
                </div>
                {renderFilterOptions(departments, 'department', departmentCounts)}
            </div>
            <div className="relative">
                <div
                    onClick={() => toggleDropdown('country')}
                    className="flex text-sm items-center cursor-pointer hover:font-semibold"
                >
                    Country <ChevronDown/>
                </div>
                {renderFilterOptions(countries, 'country', countryCounts)}
            </div>
            <div className="relative">
                <div
                    onClick={() => toggleDropdown('state')}
                    className="flex text-sm items-center cursor-pointer hover:font-semibold"
                >
                    State <ChevronDown/>
                </div>
                {renderFilterOptions(states, 'state', stateCounts)}
            </div>
            <div className="relative">
                <div
                    onClick={() => toggleDropdown('city')}
                    className="flex text-sm items-center cursor-pointer hover:font-semibold"
                >
                    City <ChevronDown/>
                </div>
                {renderFilterOptions(cities, 'city', cityCounts)}
            </div>
            <div className="relative">
                <div
                    onClick={() => toggleDropdown('remote')}
                    className="flex text-sm items-center cursor-pointer hover:font-semibold"
                >
                    Remote <ChevronDown/>
                </div>
                {renderFilterOptions(remoteOptions, 'remote', {} as { [key: string]: number })}
            </div>
        </div>
    );
};

export default JobPostingFilter;
