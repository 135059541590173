import ReactECharts from 'echarts-for-react';
import React from 'react';

type BarChartProps = {
    name: string,
    data: number[],
    onClick?: () => void,
    expand?: boolean,
    loading?: boolean,
}

export const BarChart = ({
  name,
  data,
  onClick,
  expand,
  loading,
}:BarChartProps) => {

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const option = {
    grid: {
      left: 0,
      right: 0,
      top: expand? 40 : 10,
      bottom: 0,
      containLabel: true, // Ensures labels are displayed within the grid
    },
    xAxis: {
      type: 'category',
      data: ['0-1', '1-2', '2-5', '5-10', '10-15', '15-20', '20+'],
      axisLabel: {
        fontSize: expand? 14 : 7, // Set the font size of the x-axis labels here
        rotate: 0, // Optional: Rotate labels if needed
        interval: 0, // Display all labels
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: expand? 14 : 7, // Set the font size of the x-axis labels here
        rotate: 0, // Optional: Rotate labels if needed
        interval: 0, // Display all labels
      },
    },
    series: [
      {
        data: data,
        type: 'bar',
        itemStyle: {
          color: 'darkblue', // Set the color of the bars here
        },
      },
    ],
  };

  return (
    <div
      className={`${loading ? 'animate-pulse' : ''}`}
      onClick={handleClick}>
      { loading ? (
          <div className='rounded-md w-[145px] h-20 bg-gray-300'>
          </div>
      ) : (
          <ReactECharts
              option={option}
              style={{ height: expand? '180px' : '82px', width: expand? '400px' : '145px' }}
          />
      ) }
      <p className='text-center font-bold text-xs text-green-500'>{ loading ? '' : name }</p>
    </div>
  );
};
