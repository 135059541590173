import React from 'react';
import JobPostTable from './JobPostTable';
import JobPostDetails from "./JobPostDetails";
import {PrimaryTypography} from "../../../ui/typography/typography";
import {useParams} from "react-router-dom";

export const JobPostingView = () => {

  const { id } = useParams<{ id: string }>();


  return (
    <div className='bg-gray-100 p-2'>
        <PrimaryTypography.FiveXLarge
            fontWeight='bold'
            keepDefaultMargins={true}
        >
            View Job Posts
        </PrimaryTypography.FiveXLarge>
        {id ? <JobPostDetails /> : <JobPostTable />}
    </div>
  );
};
