import React from 'react';
import {DatePicker} from "../../../../../../ui/input/date_picker/date_picker";

const JobPostSettings = () => {

    const changeDate = (timeRange: any) => {
        console.log('timeRange');
    };

    return (
        <>
            <div>
                <DatePicker
                    label='Time-Range'
                    startDate={new Date().toISOString().split('T')[0]}
                    endDate={new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0]}
                    onChange={changeDate}
                />
            </div>
        </>
    );
};

export default JobPostSettings;
