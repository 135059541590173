import React, {ChangeEvent, useState} from 'react';
import {TextInput} from "../../../../../../ui/input/text_input/text_input";
import {SelectOptions} from "../../../../../../utils/types/react_select_types";
import {Button} from "../../../../../../ui/button/button";
import AsyncCustomSelect from "../../../../../../ui/custom_react_select/async_select";
import useDebouncedJobTitleSearch from "../../../../../../utils/helpers/AsyncSelect/JobTitleGet";
import {JobDescription, showAllTypes, workType} from "../../../../../../utils/types/JobOpenings";
import AddRemoveComponent from "../../../ui/AddOrRemoveList";

interface CollapsibleData {
    id: number;
    title: string;
    labels: string[];
    isOpen: boolean;
}

const JobPostDetails = () => {

    const [showAll, setShowAll] = useState<showAllTypes>('showAll');
    const [jobDescription, setJobDescription] = useState<JobDescription>({
        id: '',
        jobOpeningId: '123',
        jobReferenceNumber: '',
        jobReferenceNumberJD: '',
        minimumWorkExperience: 0,
        jobLocation: '',
        responsibilities: [],
        educations: [],
        certifications: [],
        mandatorySkills: [],
        desirableSkills: [],
        transcript: '',
        completed: false,
    });

    const { loadOptionsDebounced } = useDebouncedJobTitleSearch();

    const [collapsible, setCollapsible] = useState<CollapsibleData[]>([
        { id: 1, title: 'Responsibilities', labels: jobDescription.responsibilities ? jobDescription.responsibilities : [], isOpen: true },
    ]);

    const handleApplyChange = () => {
        console.log('func');
    }

    const handleJobTitleChange = (selectedOptions: SelectOptions) => {
        console.log('func');
    };

    const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
    };

    const handleLabelsChange = (id: number, updatedLabels: string[]) => {
        setCollapsible(
            collapsible.map(collapsible =>
                collapsible.id === id ? { ...collapsible, labels: updatedLabels } : collapsible,
            ),
        );
    };

    return (
        <>
            <div className='relative flex flex-col gap-4 h-full'>
                <div className='absolute top-0 right-0'>
                    <Button
                        onClick={() => handleApplyChange()}
                        variant={'primary'}
                        size={'medium'}
                    >
                        Import from JD
                    </Button>
                </div>
                <div className='flex-col gap-40 w-1/3'>
                    <div className='text-gray-800 py-1 text-sm'>
                        Location
                    </div>
                    <AsyncCustomSelect
                        value={{
                            "label": "Design Lead",
                            "value": "65cefb272b072cd76ece0261"
                        }}
                        defaultOptions={[
                            {
                                "value": "65cefec92b072cd76ece24a1",
                                "label": "*Technical Support Representative"
                            },
                        ]}
                        onChange={handleJobTitleChange}
                        loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
                        isMulti={false}
                    />
                    <div className='text-gray-800 py-1 text-sm'>
                        Department
                    </div>
                    <AsyncCustomSelect
                        value={{
                            "label": "Design Lead",
                            "value": "65cefb272b072cd76ece0261"
                        }}
                        defaultOptions={[
                            {
                                "value": "65cefec92b072cd76ece24a1",
                                "label": "*Technical Support Representative"
                            },
                        ]}
                        onChange={handleJobTitleChange}
                        loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
                        isMulti={false}
                    />
                    <div className='text-gray-800 py-1 text-sm'>
                        Job Title
                    </div>
                    <AsyncCustomSelect
                        value={{
                            "label": "Design Lead",
                            "value": "65cefb272b072cd76ece0261"
                        }}
                        defaultOptions={[
                            {
                                "value": "65cefec92b072cd76ece24a1",
                                "label": "*Technical Support Representative"
                            },
                        ]}
                        onChange={handleJobTitleChange}
                        loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
                        isMulti={false}
                    />
                    <div className='text-gray-800 py-1 text-sm'>
                        Working Method
                    </div>
                    <AsyncCustomSelect
                        value={{
                            "label": "Design Lead",
                            "value": "65cefb272b072cd76ece0261"
                        }}
                        defaultOptions={[
                            {
                                "value": "65cefec92b072cd76ece24a1",
                                "label": "*Technical Support Representative"
                            },
                        ]}
                        onChange={handleJobTitleChange}
                        loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
                        isMulti={false}
                    />
                </div>
                <div className='flex-col gap-40 '>
                    <div className='text-gray-800 py-1 text-sm'>
                        Role Details
                    </div>
                    <TextInput
                        name={'x'}
                        value={''}
                        type={'string'}
                        onChange={onSearchTermChange}
                    />
                    <div className='text-gray-800 py-1 text-sm'>
                        Responsibilities
                    </div>
                    <TextInput
                        name={'x'}
                        value={''}
                        type={'string'}
                        onChange={onSearchTermChange}
                    />
                </div>
                <div className='text-gray-800 py-1 text-sm'>
                    Required Skills
                </div>
                <div className='flex gap-4'>
                    <div className='w-1/3'>
                        <TextInput
                            name={'x'}
                            value={''}
                            type={'string'}
                            onChange={onSearchTermChange}
                        />
                    </div>
                    <div className='w-1/3'>
                        <TextInput
                            name={'x'}
                            value={''}
                            type={'string'}
                            onChange={onSearchTermChange}
                        />
                    </div>
                    <AddRemoveComponent
                        initialLabels={[]}
                        onLabelsChange={(updatedLabels) => handleLabelsChange(1, updatedLabels)}
                    />
                </div>
                <div className='flex gap-4'>
                    <div className='w-1/3'>
                        <TextInput
                            name={'x'}
                            value={''}
                            type={'string'}
                            onChange={onSearchTermChange}
                        />
                    </div>
                    <div className='w-1/3'>
                        <TextInput
                            name={'x'}
                            value={''}
                            type={'string'}
                            onChange={onSearchTermChange}
                        />
                    </div>
                    <AddRemoveComponent
                        initialLabels={[]}
                        onLabelsChange={(updatedLabels) => handleLabelsChange(1, updatedLabels)}
                    />
                </div>
                <div className='text-gray-800 py-1 text-sm'>
                    Desired Skills
                </div>
                <div className='flex gap-4'>
                    <div className='w-1/3'>
                        <TextInput
                            name={'x'}
                            value={''}
                            type={'string'}
                            onChange={onSearchTermChange}
                        />
                    </div>
                    <div className='w-1/3'>
                        <TextInput
                            name={'x'}
                            value={''}
                            type={'string'}
                            onChange={onSearchTermChange}
                        />
                    </div>
                </div>
                <div className='flex-col gap-40 '>
                    <div className='text-gray-800 py-1 text-sm'>
                        Additional Requirements
                    </div>
                    <TextInput
                        name={'x'}
                        value={''}
                        type={'string'}
                        onChange={onSearchTermChange}
                    />
                    <div className='text-gray-800 py-1 text-sm'>
                        Perks and Benefits
                    </div>
                    <TextInput
                        name={'x'}
                        value={''}
                        type={'string'}
                        onChange={onSearchTermChange}
                    />
                </div>
            </div>
        </>
    );
};

export default JobPostDetails;
